export default [
  'Impact riveting',
  'Squeeze riveting',
  'Semi-tubular rivets',
  'Solid rivets',
  'Blind rivets',
  'Threaded inserts',
  'Press in captive hardware',
  'Screws and nuts',
  'Bearing installation',
  'Bushing installation',
  'Honing',
  'Click bond',
  'Ground stud installation',
  'Heli-coil inserts',
  'Adhesive/sealant bonding',
];
