import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './components/routes/Home';
import WhoWeAre from './components/routes/WhoWeAre';
import WhatWeDo from './components/routes/WhatWeDo';
import Contact from './components/routes/Contact';
import Footer from './components/Footer';
import Suppliers from './components/routes/Suppliers';
import Quality from './components/routes/WhoWeAre/Quality';
import Facilities from './components/routes/WhoWeAre/Facilities';
import Fabrication from './components/routes/WhatWeDo/Fabrication';
import Machining from './components/routes/WhatWeDo/Machining';
import Assembly from './components/routes/WhatWeDo/Assembly';
import Hydroforming from './components/routes/WhatWeDo/Hydroforming';
import ScrollToTop from './hooks/ScrollToTop';
import {MobileNavProvider} from './hooks/useMobileNav';

import './styles/App.css';
import './styles/Main.css';

/**
 * Main entry point of the Anoroc web app
 * @return {JSX.Element}
 * @constructor
 */
function App() {
  return (
    <Router>
      <div className="App">
        <div id="outer-container">
          <MobileNavProvider>
            <NavBar />
            <main id="page-wrap">
              <ScrollToTop />
              <Routes>
                <Route
                  path="/whoweare"
                  element={<WhoWeAre />}
                />
                <Route
                  path="/whoweare/quality"
                  element={<Quality />}
                />
                <Route
                  path="/whoweare/facilities"
                  element={<Facilities />}
                />
                <Route
                  path="/whatwedo"
                  element={<WhatWeDo />}
                />
                <Route
                  path="/whatwedo/fabrication"
                  element={<Fabrication />}
                />
                <Route
                  path="/whatwedo/machining"
                  element={<Machining />}
                />
                <Route
                  path="/whatwedo/assembly"
                  element={<Assembly />}
                />
                <Route
                  path="/whatwedo/hydroforming"
                  element={<Hydroforming />}
                />
                <Route
                  path="/suppliers"
                  element={<Suppliers />}
                />
                <Route
                  path="/contact"
                  element={<Contact />}
                />
                <Route
                  path="*"
                  element={<Home />}
                />
              </Routes>
              <Footer />
            </main>
          </MobileNavProvider>
        </div>
      </div>
    </Router>
  );
}

export default App;
