import PropTypes from 'prop-types';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Lazy, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const Gallery = (props) => {
  return (
    <Swiper
      style={{
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      }}
      lazy={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      loop
      modules={[Lazy, Pagination, Navigation]}
      className="mySwiper"
    >
      {props.photos && props.photos.map((item) => (
        <SwiperSlide key={item}>
          <img alt={item} src={item} className="swiper-lazy" />
          <div className="swiper-lazy-preloader swiper-lazy-preloader-white">
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

Gallery.propTypes = {
  photos: PropTypes.any.isRequired,
};

export default Gallery;
