import {createContext, useContext, useState} from 'react';

export const useMobileNav = () => {
  return useContext(MobileNavContext);
};

const MobileNavContext = createContext({isMenuOpen: false});

export const MobileNavProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MobileNavContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
    }}>
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </MobileNavContext.Provider>
  );
};
