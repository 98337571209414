import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {BallTriangle} from 'react-loader-spinner';
import {AiOutlineRollback} from 'react-icons/ai';

const PDFModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [numPages, setNumPages] = useState(null);

  // prevent scrolling on the main page while modal is visible
  useEffect(() => {
    if (visible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [visible]);

  // set number of pages when document has loaded
  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  return visible ? (
    <div className="Modal-Container">
      <button
        className="PDF-Header"
        onClick={() => {
          setVisible(false);
        }}
      >
        <span>
            Go Back
        </span>
        <AiOutlineRollback />
      </button>
      <div className="PDF-Container">
        <Document
          file={props.source}
          options={{workerSrc: '/pdf.worker.js'}}
          loading={<BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#fff"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{
              margin: 0,
              position: 'absolute',
              top: '50%',
              left: '50%',
              msTransform: 'translate(-50%, -50%)',
              transform: 'translate(-50%, -50%)',
            }}
            visible={true}
          />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </div>
  ) : (
      <div className="Medium-Padding-Top">
        <a
          href={props.source}
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
          }}>
          <button>{props.buttonText}</button>
        </a>
      </div>
  );
};

PDFModal.propTypes = {
  buttonText: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default PDFModal;
