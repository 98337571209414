import ImageContainer from '../../../containers/FWImageContainer';
import Container from '../../../containers/FWContainer';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import Page from '../../../containers/Page';
import PDFModal from '../../../PDFModal';

const Quality = (props) => {
  const {width} = useWindowDimensions();

  return (
    <Page>
      <ImageContainer
        extraTint
        lowRes="/images/background_images/6-compressed.webP"
        fullRes="/images/background_images/6.webP"
      >
        <h2 className="Page-Section-Title">
          {'Impeccable quality. On time, every time.'}
        </h2>
        <p className="Page-Section-Text">
          {'Our comprehensive quality management system is ' +
              'certified and registered to meet the requirements of ' +
              'the US Department of Defense, NASA, and the FAA'}
        </p>
      </ImageContainer>
      <Container>
        <h2 className="Page-Section-Title">
            Quality Management System Certifications
        </h2>
        <p className="Page-Section-Text">
          {'Anoroc conducts complete and thorough inspections, providing ' +
              'AS9102 inspection documentation, SPC analysis, and CMM ' +
              'inspection reports.'}
        </p>
        <div className={`${width > 640 ? 'Flex' : ''}`}>
          <div className={`${width > 640 ? 'Right-Margin Align-Center' : ''}`}>
            <p className="Page-Section-Text">
              <b>
                ISO 9001:2015
              </b>
            </p>
            <p className="Page-Section-Text">
              {'We use a comprehensive certified quality management ' +
                  'system that demonstrates our ability to  ' +
                  'consistently provide parts and services ' +
                  'that meet statutory and regulatory requirements.'}
            </p>
          </div>
          <div className={`${width > 640 ? 'Left-Margin' : ''}`}>
            <p className="Page-Section-Text">
              <b>
                AS9100:2016
              </b>
            </p>
            <p className="Page-Section-Text">
              {'Our quality management system is ' +
                  'also certified to the most recent ' +
                  'version of an additional standard designed for ' +
                  'the aviation, space, and defense industry, which also ' +
                  'outlines civil and military aviation requirements. '}
            </p>
          </div>
        </div>
        <PDFModal
          buttonText="See our certificate"
          source="/QMS_Certificate.pdf"
        />
        <h2 className="Page-Section-Title Large-Padding-Top">
            Northrop Grumman Top Honors Awardee
        </h2>
        <div className="Flex">
          <div className="Image-Wrapper Right-Margin">
            <img
              src="https://investor.northropgrumman.com/sites/g/files/knoqqb52606/themes/site/nir_pid453/dist/img/NGC-logo-white.png"
              alt="Northrop Grumman Logo"
            />
          </div>
          <p className="Page-Section-Text Left-Margin">
            {'We are proudly recognized as one of only 74 ' +
                  'companies awarded with the Northrop Grumman ' +
                  '2013 Platinum Source Preferred status.'}
          </p>
        </div>
        <div className="Page-Section-Text Bottom-Margin">
          <p className="Text-Align-Right Gray-Text Smaller-Text">
            {'"Northrop Grumman grants the awards only to ' +
                  'those Aerospace Systems sector suppliers ' +
                  'that achieve distinction in product quality, ' +
                  'on-time delivery, customer satisfaction, and robust, ' +
                  'lean processes." '}
          </p>
          <p className="Text-Align-Right Smaller-Text">
            {'- Debbie Bohacs, Northrop Grumman'}
          </p>
        </div>
        <h2 className="Page-Section-Title Large-Padding-Top">
            ITAR Registered
        </h2>
        <div className="Flex Top-Margin">
          <div className="Image-Wrapper Right-Margin White-Background">
            <img src="https://cdnblog.filecloud.com/blog/wp-content/uploads/2018/05/ITAR-REGISTERED_high-660x3041.png"/>
          </div>
          <div className="Align-Center">
            <p className="Page-Section-Text">
              {'Anoroc is ITAR registered with the US Department of ' +
                    'Defense.'}
            </p>
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default Quality;
