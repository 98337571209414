import FWContainer from '../../containers/FWContainer';
import Page from '../../containers/Page';

const Suppliers = (props) => {
  return (
    <Page>
      <FWContainer>
        <h2 className="Page-Section-Title Medium-Padding-Top">
            Suppliers
        </h2>
        <p className="Page-Section-Text">
          {'Please click the button below to download our ' +
              'purchase order terms and conditions. '}
        </p>
        <a href="/ANOROC-Terms-and-Conditions.pdf">
          <button>Download Terms and Conditions</button>
        </a>
      </FWContainer>
    </Page>
  );
};

export default Suppliers;
