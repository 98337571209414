import PropTypes from 'prop-types';

const Card = (props) => {
  return (
    <div className="Card">
      <div className="Card-Title">
        {props.title && <h4>{props.title}</h4>}
      </div>
      <div className="Card-Content-Area">
        {props.bullets && (
          <ul>
            {
              props.bullets.map((item) => (
                <li key={`${props.title} ${item}`}>
                  <p className="Card-Text">{item}</p>
                </li>
              ))
            }
          </ul>
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  bullets: PropTypes.array,
};

export default Card;
