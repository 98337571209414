import ImageContainer from '../../../containers/FWImageContainer';
import Container from '../../../containers/FWContainer';
import Gallery from '../../../Gallery';
import machiningPhotos from '../../../../constants/machining/machiningPhotos';
import Page from '../../../containers/Page';

const Machining = (props) => {
  return (
    <Page>
      <ImageContainer
        extraTint
        lowRes="/images/background_images/12-compressed.webP"
        fullRes="/images/background_images/12.webP"
      >
        <h2 className="Page-Section-Title">CNC Machining Services</h2>
        <p className="Page-Section-Text">
          {'Our 5-axis CNC machining technology produces ' +
              'parts of the highest quality'}
        </p>
        <button>Get In Touch</button>
      </ImageContainer>
      <Container>
        <h2 className="Page-Section-Title">
          Some of our CNC machining capabilities
        </h2>
        <p className="Page-Section-Text">
          {'We provide a variety of CNC services. (to-do: add capabilities)'}
        </p>
      </Container>
      <Container>
        <h2 className="Page-Section-Title">
          See our machining work
        </h2>
        <Gallery photos={machiningPhotos} />
      </Container>
    </Page>
  );
};

export default Machining;
