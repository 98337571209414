import {Link} from 'react-router-dom';
import ImageContainer from '../containers/FWImageContainer';
import Map from '../Map';
import Container from '../containers/FWContainer';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ClientCard from '../containers/ClientCard';
import clients from '../../constants/clients';
import HWContainer from '../containers/HWContainer';
import {FiExternalLink} from 'react-icons/fi';
import Page from '../containers/Page';

const Home = (props) => {
  const {width} = useWindowDimensions();

  return (
    <Page>
      <ImageContainer
        extraTint
        lowRes="/images/background_images/5-compressed.webP"
        fullRes="/images/background_images/5.webP"
      >
        <div>
          <h2 className="Page-Section-Title">Precision when it matters most</h2>
          <p className="Page-Section-Text">
            {'State of the art equipment and unmatched attention ' +
                  'to detail allows us to serve some of the most reputable ' +
                  'organizations around the globe'}
          </p>
        </div>
        <Link to='/whoweare'>
          <button>Learn more</button>
        </Link>
      </ImageContainer>
      <Container>
        <h2 className="Page-Section-Title">
          {'Trusted by a diverse group of distinguished clients'}
        </h2>
        <div className="Image-Card-Container">
          {clients.map((client) => (
            <ClientCard
              key={client.title}
              text={client.title}
              link={client.link}
            />
          ))}
        </div>
      </Container>
      <Container>
        <h2 className="Page-Section-Title">
          {'Where you can find our work'}
        </h2>
        <p className="Page-Section-Text">
          {'The parts we manufacture and assemble are essential ' +
              'components of a variety of commercial, military, and space ' +
              'applications. '}
        </p>
        <p className="Page-Section-Subtitle Large-Padding-Top">
          Global Hawk
        </p>
      </Container>
      <HWContainer noPad>
        <div>
          <p className="Page-Section-Text Gray-Text Smaller-Text">
            {'"The RQ-4 Global Hawk unmanned aircraft ' +
                  'system (UAS) is the premier provider ' +
                  'of persistent intelligence, surveillance ' +
                  'and reconnaissance information." '}
          </p>
          <p className="Page-Section-Text
          Smaller-Text Flex Space-Between">
            <span>
              {'- Northrop Grumman '}
            </span>
            <a
              className="Align-Center"
              href="https://www.northropgrumman.com/what-we-do/air/global-hawk/"
            >
              <FiExternalLink />
            </a>
          </p>
        </div>
        <div className="Image-Wrapper">
          <img
            loading="lazy"
            alt="Global Hawk"
            src="/images/other/Global_Hawk.webP" />
        </div>
      </HWContainer>
      <Container>
        <p className="Page-Section-Subtitle">
          Triton
        </p>
      </Container>
      <HWContainer noPad>
        <div>
          <p className="Page-Section-Text Gray-Text Smaller-Text">
            {'"Northrop Grumman’s MQ-4C Triton unmanned aircraft ' +
                'system (UAS) provides real-time intelligence, ' +
                'surveillance and reconnaissance (ISR) over ' +
                'vast ocean and coastal regions." '}
          </p>
          <p className="Page-Section-Text
          Smaller-Text Flex Space-Between">
            <span>
              {'- Northrop Grumman '}
            </span>
            <a
              className="Align-Center"
              href="https://www.northropgrumman.com/what-we-do/air/triton/"
            >
              <FiExternalLink />
            </a>
          </p>
        </div>
        <div className="Image-Wrapper">
          <img
            loading="lazy"
            alt="Global Hawk"
            src="/images/other/Triton.webP" />
        </div>
      </HWContainer>
      <Container>
        <p className="Page-Section-Subtitle">
          Other Commercial and Military Aircrafts
        </p>
        {width > 640 ? (
            <div className="Flex">
              <ul className="Decorative-List Right-Margin Align-Center">
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>E-2 Hawkeye</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>C-17</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>F-35</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>F-22</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>F-18</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>F-16</div>
                  </div>
                </li>
              </ul>
              <ul className="Decorative-List Left-Margin">
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>Boeing 787</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>Boeing 777</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>Boeing 767</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>Boeing 757</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>Boeing 747</div>
                  </div>
                </li>
                <li>
                  <div className="Flex Space-Between">
                    <div>+</div>
                    <div>Boeing 737</div>
                  </div>
                </li>
              </ul>
            </div>
        ) : (
            <ul className="Decorative-List Align-Center">
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>E-2 Hawkeye</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>C-17</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>F-35</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>F-22</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>F-18</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>F-16</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>Boeing 787</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>Boeing 777</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>Boeing 767</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>Boeing 757</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>Boeing 747</div>
                </div>
              </li>
              <li>
                <div className="Flex Space-Between">
                  <div>+</div>
                  <div>Boeing 737</div>
                </div>
              </li>
            </ul>
        )}
      </Container>
      <Container>
        <h2 className="Page-Section-Title">
          {'We\'re located in Los Angeles, CA'}
        </h2>
        <p className="Page-Section-Text">
          {'Our manufacturing facility is a concrete tilt-up with ' +
              'approximately 15,000 square feet  allowing us to ' +
              'offer complete CNC precision sheet metal fabrication ' +
              'including 5 axis machining services.  '}
        </p>
        <div className={width <= 640 ? `` : 'Flex Space-Around'}>
          <Map />
          <div className="No-Wrap Align-Center">
            <div className="Smaller-Text">
              <p className="Page-Section-Text">
                Anoroc Precision Sheet Metal Inc.<br />
                19122 S. Santa Fe Ave<br />
                Rancho Dominguez, CA 90221
              </p>
            </div>
            <a href="https://www.google.com/maps/place/Anoroc+Precision+Sheet+Metal+Inc/@33.8572204,-118.2173727,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2caf624ef1895:0xaab023c008947542!8m2!3d33.857224!4d-118.2151558">
              <button>Open in Google Maps</button>
            </a>
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default Home;
