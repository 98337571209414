import ImageContainer from '../../containers/FWImageContainer';
import HWContainer from '../../containers/HWContainer';
import {Link} from 'react-router-dom';
import Page from '../../containers/Page';

const WhatWeDo = (props) => {
  return (
    <Page>
      <ImageContainer
        extraTint
        lowRes="/images/background_images/4-compressed.webP"
        fullRes="/images/background_images/4.webP"
      >
        <h2 className="Page-Section-Title">Our services</h2>
        <p className="Page-Section-Text">
          {'We provide premium quality precision sheet ' +
              'metal production, fabrication, and manufacturing ' +
              'services for some of the most reputable ' +
              'organizations in the military and ' +
              'aerospace industries'}
        </p>
        <Link to="/contact">
          <button>Get In Touch</button>
        </Link>
      </ImageContainer>
      <HWContainer>
        <div>
          <img
            alt="Fabrication"
            src="/images/fabrication_photos/amada_small.webP"
          />
        </div>
        <div>
          <h2 className="Page-Section-Title">Sheet Metal Fabrication</h2>
          <p className="Page-Section-Text">
            {'With decades of experience, our team of experts offers a ' +
                'wide assortment of precision sheet metal fabrication ' +
                'services.'}
          </p>
          <Link to='/whatwedo/fabrication'>
            <button>Learn more</button>
          </Link>
        </div>
      </HWContainer>
      <HWContainer>
        <div>
          <h2 className="Page-Section-Title">CNC Machining</h2>
          <p className="Page-Section-Text">
            {'Our 5-axis CNC machining technology produces ' +
                'parts of the highest quality.'}
          </p>
          <Link to='/whatwedo/machining'>
            <button>Learn more</button>
          </Link>
        </div>
        <div>
          <img alt="Machining" src="/images/machining_photos/v-40.webP" />
        </div>
      </HWContainer>
      <HWContainer>
        <div>
          <img alt="Assembly" src="/images/assembly_photos/assembly.webP" />
        </div>
        <div>
          <h2 className="Page-Section-Title">Assembly</h2>
          <p className="Page-Section-Text">
            {'Our team of professionals are capable of handling ' +
                'even the most complex assemblies'}
          </p>
          <Link to='/whatwedo/assembly'>
            <button>Learn more</button>
          </Link>
        </div>
      </HWContainer>
      <HWContainer>
        <div>
          <h2 className="Page-Section-Title">Hydroforming</h2>
          <p className="Page-Section-Text">
            {'By utilizing hydroforming techniques, we excel in ' +
                'producing strong, lightweight assemblies and structures.'}
          </p>
          <Link to='/whatwedo/hydroforming'>
            <button>Learn more</button>
          </Link>
        </div>
        <div>
          <img alt="Machining" src="/images/hydroforming_photos/1.webP" />
        </div>
      </HWContainer>
    </Page>
  );
};

export default WhatWeDo;
