import PropTypes from 'prop-types';
import {useState} from 'react';

const FWImageContainer = (props) => {
  const [ready, setReady] = useState(false);

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function() {
        resolve(img);
      };
      img.onerror = img.onabort = function() {
        reject(src);
      };
      img.src = src;
    });
  };

  const loadFullRes = () => {
    preloadImage(props.fullRes).then((r) => {
      setReady(true);
      console.log('src is now full res image:', props.fullRes);
    });
  };

  return (
    <div className={`Page-Section-Wrapper`}>
      <div className="Page-Section-Content Flex-Center-Items">
        <div className={`ImageTint ${props.extraTint && 'ExtraTint'}`}>
          <img
            width="100%"
            height={500}
            src={ready ? props.fullRes : props.lowRes}
            alt="Background image"
            onLoad={loadFullRes}
          />
        </div>
        <div className="Page-Section-Inner-Content-Wrapper Absolute">
          <div className="Page-Section-Inner-Content">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

FWImageContainer.propTypes = {
  extraTint: PropTypes.bool,
  lowRes: PropTypes.string.isRequired,
  fullRes: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default FWImageContainer;
