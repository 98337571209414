import PropTypes from 'prop-types';

const HWContainer = (props) => {
  return (
    <div className={`${props.noPad ? '' : 'Page-Section-Wrapper'}`}>
      <div className="Page-Section-Content">
        <div className="Page-Section-Inner-Content-Wrapper">
          <div className="Page-Section-Inner-Content Half-Width-Children">
            <div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HWContainer.propTypes = {
  children: PropTypes.any,
  noPad: PropTypes.bool,
};

export default HWContainer;
