import FWContainer from '../../containers/FWContainer';
import Map from '../../Map';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {Link} from 'react-router-dom';
import {MdOutlineEmail} from 'react-icons/md';
import {AiOutlineCopy} from 'react-icons/ai';
import staff from '../../../constants/staff';
import Page from '../../containers/Page';

const Contact = (props) => {
  const {width} = useWindowDimensions();

  return (
    <Page>
      <FWContainer>
        <h2 className="Page-Section-Title">
            Anoroc Precision Sheet Metal, Inc.
        </h2>
        <div className={width <= 640 ? `` : 'Flex Space-Around'}>
          <Map />
          <div className="No-Wrap Align-Center">
            <p className="Page-Section-Text">
                19122 S. Santa Fe Ave<br />
                Rancho Dominguez, CA 90221<br /><br />
                +1 (310) 515-6015<br />
                sales@anoroc.com
            </p>
            <a href="https://www.google.com/maps/place/Anoroc+Precision+Sheet+Metal+Inc/@33.8572204,-118.2173727,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2caf624ef1895:0xaab023c008947542!8m2!3d33.857224!4d-118.2151558">
              <button>Open in Google Maps</button>
            </a>
          </div>
        </div>
        <h2 className="Page-Section-Title Medium-Padding-Top">
            Staff
        </h2>
        <ul className="Staff-List">
          {staff.map((item) => (
            <li
              key={`${item.name}-${item.title}`}
              className="Staff-List-Item"
            >
              <div className="Staff-List-Header">
                <h4>{item.title}</h4>
                <h4 className="Text-Align-Right">{item.name}</h4>
              </div>
              <div className="Flex Space-Between">
                <p className="Align-Center">{item.email}</p>
                <p className="Align-Center">
                  <Link
                    to="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      if ('clipboard' in navigator) {
                        await navigator.clipboard.writeText(item.email);
                      } else {
                        document.execCommand('copy', true, item.email);
                      }
                    }}
                  >
                    <AiOutlineCopy className="Staff-List-Item-Icon" />
                  </Link>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location = `mailto:${item.email}`;
                    }}
                  >
                    <MdOutlineEmail className="Staff-List-Item-Icon" />
                  </Link>
                </p>
              </div>
            </li>
          ))}
        </ul>
      </FWContainer>
    </Page>
  );
};

export default Contact;
