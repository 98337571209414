import ImageContainer from '../../../containers/FWImageContainer';
import Container from '../../../containers/FWContainer';
import Gallery from '../../../Gallery';
import hydroformingPhotos
  from '../../../../constants/hydroforming/hydroformingPhotos';
import Page from '../../../containers/Page';

const Hydroforming = (props) => {
  return (
    <Page>
      <ImageContainer
        extraTint
        lowRes="/images/background_images/9-compressed.webP"
        fullRes="/images/background_images/9.webP"
      >
        <h2 className="Page-Section-Title">Hydroforming Services</h2>
        <p className="Page-Section-Text">
          {'By utilizing hydroforming techniques, we excel in ' +
              'producing strong, lightweight assemblies and structures.'}
        </p>
        <button>Get In Touch</button>
      </ImageContainer>
      <Container>
        <h2 className="Page-Section-Title">
            Some of our hydroforming capabilities
        </h2>
        <p className="Page-Section-Text">
          {'We provide hydroforming services. (to-do: add capabilities)'}
        </p>
      </Container>
      <Container>
        <h2 className="Page-Section-Title">
            See our hydroforming work
        </h2>
        <Gallery photos={hydroformingPhotos} />
      </Container>
    </Page>
  );
};

export default Hydroforming;
