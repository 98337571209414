import {useMobileNav} from '../../../hooks/useMobileNav';

const Page = (props) => {
  const {isMenuOpen, toggleMenu} = useMobileNav();

  return (
    <div
      className="Page"
      onClick={() => {
        if (isMenuOpen) {
          toggleMenu();
        }
      }}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </div>
  );
};

export default Page;
