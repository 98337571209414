export default [
  {
    title: 'Hexagon (Romer) Absolute Arm 7-Axis Model 7525',
    bullets: [
      '2.5 meter measurement volume',
      'PC-Dmis flexible metrology software',
    ],
  },
  {
    title: 'OMAX 5555 Waterjet Center System',
    bullets: [
      '52 in. x 56 in. max part size capability',
      '50 horsepower',
      '180 inches per minute',
    ],
  },
  {
    title: 'OMAX 2652 Waterjet Center System',
    bullets: [
      '52 in. x 56 in. max part size capability',
      '30 horsepower',
      '180 inches per minute',
    ],
  },
  {
    title: 'Amada Pega 344 CNC Turret Fabricator',
    bullets: [
      '33 ton capacity',
      '220 hits per mibute',
      '58 tool stations including 2 auto index stations',
    ],
  },
  {
    title: 'Amada CNC Press Brake Model RG50',
    bullets: [
      '55 ton capacity',
      '82 in. overall bending length',
      '15.75 in. throat',
      '3-Axis CNC Control',
    ],
  },
  {
    title: 'Amada CNC Hydraulic Press Brake Model HFB 2204',
    bullets: [
      '242 US ton capacity',
      '168 in. overall bending length',
      '16.5 in. throat',
      '8 standard axes of control',
    ],
  },
  {
    title: 'Amada Drilling & Tapping Machine Model CTS-54',
    bullets: [
      'Cordax capabilities',
    ],
  },
  {
    title: 'Leadwell 5 Axis Machining Center Modal V40iT',
    bullets: [
      '12,000 RPM',
      '40 in./ 25 in./ 21 in. X-/Y-/Z-axis travel',
      '24 in. diameter max part swing',
      '12 in. max part height',
    ],
  },
  {
    title: 'Greenerd Hydroform Press Model HCA-50-60R8',
    bullets: [
      'Ram force adjustable from 10 to 50',
      'Table 26 in. x 15 in',
      'Throat 11 in.',
      '20 horsepower motor',
      '1200 RPM',
    ],
  },
  {
    title: 'Newton Mechanical Shear',
    bullets: [
      '10 ft.-1/4 in. gauge',
    ],
  },
  {
    title: 'Hemsaw Dual Column Band Saw Model H130AHA-DC',
    bullets: [
      'Main capacity 0 to 20 in. wide, 0 to 20 in. high',
      'Bar feed 0 to 48 in.',
      '10 horsepower motor',
      '1200 RPM',
    ],
  },
  {
    title: 'Blanchard Grinder Model 22D',
    bullets: [
      'Grind capacity of 42 in. O.D. with height of 24 in.',
      '50 horsepower spindle motor',
      'Table speeds 6 to 33 RPM',
    ],
  },
  {
    title: 'Gardner Double Disk Grinding Machines',
    bullets: [
      'Hydraulic and manual controlled grinding',
      'Various sizes',
    ],
  },
  {
    title: 'Bridgeport Mill (Conventional)',
    bullets: [
      '42 in. X-axis travel',
      '15 in. Y-axis travel',
      '12 in. Z-axis travel',
    ],
  },
  {
    title: 'Bridgeport EZ Trak with CNC',
    bullets: [
      '48 in. X-axis travel',
      '15 in. Y-axis travel',
      '12 in. Z-axis travel',
    ],
  },
  {
    title: 'Timesavers Model 137-1HDMW',
    bullets: [
      '36 in. width',
    ],
  },
  {
    title: 'VideoJet Part Marking System Model 1701AF',
    bullets: [],
  },
];
