import Container from '../../../containers/FWContainer';
import Card from '../../../containers/Card';
import Page from '../../../containers/Page';
import equipment from '../../../../constants/equipment';

const Facilities = (props) => {
  return (
    <Page>
      <Container>
        <h2 className="Page-Section-Title">
          {'Our facility in Los Angeles'}
        </h2>
        <p className="Page-Section-Text">
          {'Our manufacturing facility is a concrete tilt-up with ' +
              'approximately 20,000 square feet  allowing us to offer ' +
              'complete precision sheet metal fabrication and CNC ' +
              'machining services.'}
        </p>
        <div className="Card-Container">
          {equipment.map((item) => (
            <Card
              title={item.title}
              bullets={item.bullets}
              key={item.title}
            />
          ))}
        </div>
      </Container>
    </Page>
  );
};

export default Facilities;
