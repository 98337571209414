export default [
  '/images/fabrication_photos/1.webP',
  '/images/fabrication_photos/2.webP',
  '/images/fabrication_photos/3.webP',
  '/images/fabrication_photos/4.webP',
  '/images/fabrication_photos/5.webP',
  '/images/fabrication_photos/6.webP',
  '/images/fabrication_photos/7.webP',
  '/images/fabrication_photos/8.webP',
];
