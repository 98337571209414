export default [
  {
    title: 'Northrop Grumman',
    link: 'https://www.northropgrumman.com/',
  },
  {
    title: 'Northrop Grumman Space Systems',
    link: 'https://www.northropgrumman.com/who-we-are/business-sectors/space-systems/',
  },
  {
    title: 'UTC Aerospace Systems (Now Collins Aerospace)',
    link: 'https://www.collinsaerospace.com/',
  },
  {
    title: 'General Dynamics',
    link: 'https://www.gd.com/',
  },
  {
    title: 'Magellan Aerospace',
    link: 'https://magellan.aero/',
  },
  {
    title: 'Air Industries Group',
    link: 'https://airindustriesgroup.com/',
  },
  {
    title: 'Ontic',
    link: 'https://ontic.co/',
  },
  {
    title: 'Glenair',
    link: 'https://www.glenair.com/',
  },
];
