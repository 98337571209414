import {FiExternalLink} from 'react-icons/fi';
import PropTypes from 'prop-types';

const ClientCard = (props) => {
  const {title, text, link} = props;
  return (
    <div className="Image-Card">
      <div className="Image-Card-Content-Wrapper">
        <div className="Image-Card-Text-Area">
          {title && <h4>{title}</h4>}
          {text && text}
        </div>
        <a className="Image-Card-Link" href={link}>
          <FiExternalLink />
        </a>
      </div>
    </div>
  );
};

ClientCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};

export default ClientCard;
