import ImageContainer from '../../../containers/FWImageContainer';
import Container from '../../../containers/FWContainer';
import assemblyCapabilities
  from '../../../../constants/assembly/assemblyCapabilities';
import Gallery from '../../../Gallery';
import assemblyPhotos from '../../../../constants/assembly/assemblyPhotos';
import {BiCheck} from 'react-icons/bi';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import Page from '../../../containers/Page';

const Assembly = (props) => {
  const halfLength = Math.ceil(assemblyCapabilities.length / 2);
  const left = assemblyCapabilities.slice(0, halfLength);
  const right = assemblyCapabilities.slice(halfLength);
  const {width} = useWindowDimensions();

  return (
    <Page>
      <ImageContainer
        extraTint
        lowRes="/images/background_images/13-compressed.webP"
        fullRes="/images/background_images/13.webP"
      >
        <h2 className="Page-Section-Title">Assembly Services</h2>
        <p className="Page-Section-Text">
          {'Our team of professionals are capable of handling ' +
              'even the most complex assemblies'}
        </p>
        <button>Get In Touch</button>
      </ImageContainer>
      <Container>
        <h2 className="Page-Section-Title">
          Some of our assembly capabilities
        </h2>
        <p className="Page-Section-Text">
          {'A vast collection of equipment allows us to provide ' +
              'quality assembly services for a variety of hardware. ' +
              'This includes pneumatic compression riveters, long jaw ' +
              'portable compression riveters, and presses. We are are ' +
              'capable of assembling parts that are simple, extremely ' +
              'challenging, and everything in between.'}
        </p>

        {width > 800 ? (
              <div className="Flex">
                <ul className="Decorative-List">
                  {left.map((item) => (
                    <li
                      key={item}
                      className="Capability-List-Item"
                    >
                      <div className="Flex">
                        <div className="Right-Margin"><BiCheck /></div>
                        <div>{item}</div>
                      </div>
                    </li>
                  ))}
                </ul>
                <ul className="Decorative-List">
                  {right.map((item) => (
                    <li
                      key={item}
                      className="Capability-List-Item"
                    >
                      <div className="Flex">
                        <div className="Right-Margin"><BiCheck /></div>
                        <div>{item}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
          ) : (
            <ul className="Decorative-List">
              {assemblyCapabilities.map((item) => (
                <li
                  key={item}
                  className="Capability-List-Item"
                >
                  <div className="Flex">
                    <div className="Right-Margin"><BiCheck /></div>
                    <div>{item}</div>
                  </div>
                </li>
              ))}
            </ul>
          )}
      </Container>
      <Container>
        <h2 className="Page-Section-Title">
          See our assembly work
        </h2>
        <Gallery photos={assemblyPhotos} />
      </Container>
    </Page>
  );
};

export default Assembly;
