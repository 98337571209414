import PropTypes from 'prop-types';

const FWContainer = (props) => {
  return (
    <div className="Page-Section-Wrapper">
      <div className="Page-Section-Content">
        <div className="Page-Section-Inner-Content-Wrapper">
          <div className="Page-Section-Inner-Content">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

FWContainer.propTypes = {
  children: PropTypes.any,
};

export default FWContainer;
