import useWindowDimensions from '../hooks/useWindowDimensions';
import navItems from '../constants/navItems';
import {Link} from 'react-router-dom';

const Footer = (props) => {
  const {width} = useWindowDimensions();

  return (
    <div className="Footer-Wrapper">
      <div className="Footer-Content">
        {width > 640 && (
          <div>
            <h4>Navigation</h4>
            <ul className="Footer-Nav">
              {navItems.map((item) => {
                return (
                  <>
                    <Link to={item.to}>
                      <li className="Footer-Nav-Item">
                        {item.title}
                      </li>
                    </Link>
                    {item.children &&
                          item.children.map((subItem) => {
                            return (
                              <Link
                                to={subItem.to}
                                key={`${subItem.title}-footer-nav`}
                              >
                                <li className="Footer-Nav-Sub-Item">
                                  {subItem.title}
                                </li>
                              </Link>
                            );
                          })
                    }
                  </>
                );
              })}
            </ul>
          </div>
        )}
        <div>
          <h4>Location</h4>
          <p>
            Anoroc Precision Sheet Metal, Inc.<br />
            19122 S. Santa Fe Ave<br />
            Rancho Dominguez, CA 90221
          </p>
        </div>
        <div>
          <h4>Contact</h4>
          <p>
            +1 (310) 515-6015<br />
            sales@anoroc.com
          </p>
          <p>
            Monday - Friday<br />
            7:00 AM - 5:00 PM (PST)
          </p>
        </div>
      </div>
      <div className="Footer-Copyright">
        <p>© 2022 Anoroc Precision Sheet Metal, Inc.<br />
          All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
