import ImageContainer from '../../containers/FWImageContainer';
import Container from '../../containers/FWContainer';
import {Link} from 'react-router-dom';
import Page from '../../containers/Page';

const WhoWeAre = (props) => {
  return (
    <Page>
      <ImageContainer
        extraTint={true}
        lowRes="/images/background_images/8-compressed.webP"
        fullRes="/images/background_images/8.webP"
      >
        <h2 className="Page-Section-Title">
            Our Mission
        </h2>
        <p className="Page-Section-Text">
          {'We set out to impress our clients ' +
              'with every part we make. Our key to success relies on ' +
              'offering competitive prices for parts and services at a ' +
              'level of quality that cannot be beat.'}
        </p>
      </ImageContainer>
      <Container>
        <h2 className="Page-Section-Title">
          {'We\'re a Female Minority Owned Business'}
        </h2>
        <div className="Flex">
          <div className="Align-Center Right-Margin">
            <p className="Page-Section-Text Bottom-Margin">
              {'Run by Roxanne M. Zavala and Peter J. Corona, Anoroc is a ' +
                    'proudly certified minority woman-owned and small ' +
                    'disadvantaged business.'}
            </p>
            <a href="https://www.latimes.com/archives/la-xpm-1999-sep-01-fi-5577-story.html">
              <button>Read more (LA Times)</button>
            </a>
          </div>
        </div>
      </Container>
      <Container>
        <h2 className="Page-Section-Title">
          {'We\'ve been in business since 1978'}
        </h2>
        <p className="Page-Section-Text">
          {'Over four decades of experience have culminated our state of ' +
              'the art manufacturing and quality management processes. ' +
              'We understand what it takes to thrive in industries with ' +
              'high standards.'}
        </p>
        <Link to="/whoweare/quality">
          <button>About our quality</button>
        </Link>
      </Container>
    </Page>
  );
};

export default WhoWeAre;
