import ImageContainer from '../../../containers/FWImageContainer';
import Container from '../../../containers/FWContainer';
import fabricationCapabilities
  from '../../../../constants/fabrication/fabricationCapabilities';
import fabricationPhotos
  from '../../../../constants/fabrication/fabricationPhotos';
import Gallery from '../../../Gallery';
import {BiCheck} from 'react-icons/bi';
import Page from '../../../containers/Page';


const Fabrication = (props) => {
  return (
    <Page>
      <ImageContainer
        extraTint
        fullRes="/images/background_images/11.webP"
        lowRes="/images/background_images/11-compressed.webP"
      >
        <h2 className="Page-Section-Title">Sheet Metal Fabrication Services</h2>
        <p className="Page-Section-Text">
          {'With decades of experience, our team of experts offers a ' +
              'wide assortment of precision sheet metal fabrication ' +
              'services'}
        </p>
        <button>Get In Touch</button>
      </ImageContainer>
      <Container>
        <h2 className="Page-Section-Title">
          Some of our fabrication capabilities
        </h2>
        <ul className="Decorative-List">
          {fabricationCapabilities.map((item) => (
            <li
              key={item}
              className="Capability-List-Item"
            >
              <div className="Flex">
                <div className="Right-Margin"><BiCheck /></div>
                <div>{item}</div>
              </div>
            </li>
          ))}
        </ul>
      </Container>
      <Container>
        <h2 className="Page-Section-Title">
          See our fabrication work
        </h2>
        <Gallery photos={fabricationPhotos} />
      </Container>
    </Page>
  );
};

export default Fabrication;
