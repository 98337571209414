import {Fragment} from 'react';
import Menu from 'react-burger-menu/lib/menus/slide';
import useWindowDimensions from '../hooks/useWindowDimensions';
import navItems from '../constants/navItems';
import {Link} from 'react-router-dom';
import {useMobileNav} from '../hooks/useMobileNav';

const NavBar = (props) => {
  const {width} = useWindowDimensions();
  const {isMenuOpen, stateChangeHandler, toggleMenu} = useMobileNav();

  return (
    <nav>
      <div className="Nav">
        <div className="Nav-Content">
          <div className="Nav-Logo">
            <Link to="/">
                ANOROC
            </Link>
          </div>
          {
              (width <= 640) ? (
                  <Menu
                    pageWrapId={ 'page-wrap' }
                    outerContainerId={ 'outer-container' }
                    isOpen={isMenuOpen}
                    onStateChange={(state) => {
                      stateChangeHandler(state);
                    }}
                    width='75%'
                    right
                  >
                    {navItems.map((item) => {
                      return (
                        <Fragment key={`${item.to}-mobile-link`}>
                          <Link
                            className="menu-item"
                            to={item.to}
                            onClick={() => toggleMenu()}
                          >
                            <p>{item.title}</p>
                          </Link>
                          {item.children &&
                                item.children.map((subItem) => {
                                  return (
                                    <Link
                                      className="menu-subitem"
                                      to={subItem.to}
                                      key={`${subItem.to}-mobile-navbar`}
                                      onClick={() => toggleMenu()}
                                    >
                                      {subItem.title}
                                    </Link>
                                  );
                                })
                          }
                        </Fragment>
                      );
                    })}
                  </Menu>
              ) : (
                  <div className="Nav-Links">
                    {navItems.map((item) => {
                      if (!item.excludeInNav) {
                        return (
                          <div
                            key={`${item.to}-navbar`}
                            className="Nav-Dropdown"
                          >
                            <Link to={item.to}>
                              <p>{item.title}</p>
                            </Link>
                            {item.children && (
                              <div className="Nav-Dropdown-Content">
                                {item.children.map((subItem) => {
                                  return (
                                    <Link
                                      to={subItem.to}
                                      key={`${subItem.to}-navbar`}
                                    >
                                      <p>{subItem.title}</p>
                                    </Link>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}
                    <Link to="/contact">
                      <button className="Nav-Button">
                        Get In Touch
                      </button>
                    </Link>
                  </div>
              )
          }
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
