import {GOOGLE_MAPS_API_KEY} from '../constants/secret';
import {GoogleMap, LoadScript, MarkerF} from '@react-google-maps/api';

const AnorocMap = (props) => {
  const center = {
    lat: 33.85749,
    lng: -118.215260,
  };

  return (
    <div className="Map-Container-Wrapper">
      <LoadScript
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      >
        <GoogleMap
          mapContainerStyle={{
            height: 250,
            width: '100%',
            borderRadius: '0.5em',
          }}
          center={center}
          zoom={16}
        >
          <MarkerF position={center} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default AnorocMap;
