import {useState, useEffect} from 'react';

/**
 * A function to get the current window dimensions
 * @return {{width: number, height: number}}
 */
function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
}

/**
 * A hook to get the current window dimensions
 * @return {{width: number, height: number}}
 */
export default function useWindowDimensions() {
  // eslint-disable-next-line max-len
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    /**
     * handle screen resize by updating window dimensions
     */
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
