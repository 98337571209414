export default [
  {
    name: 'Roxanne Zavala',
    title: 'President/CEO',
    email: 'rzavala@anoroc.com',
  },
  {
    name: 'Pete Corona',
    title: 'V.P. Operations & Engineering',
    email: 'pcorona@anoroc.com',
  },
  {
    name: 'Maria Lopez',
    title: 'Quality Manager',
    email: 'mlopez@anoroc.com',
  },
  {
    name: 'Roxanne Zavala',
    title: 'Request For Quote',
    email: 'rzavala@anoroc.com',
  },
  {
    name: 'Richelle Z. Kato',
    title: 'Purchase Orders/POC\'s',
    email: 'rzkato@anoroc.com',
  },
];
