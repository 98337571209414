export default [
  {
    title: 'Home',
    to: '/',
    excludeInNav: true,
  },
  {
    title: 'Who We Are',
    to: '/whoweare',
    excludeInNav: false,
    children: [
      {
        title: 'Quality',
        to: '/whoweare/quality',
      },
      {
        title: 'Facilities',
        to: '/whoweare/facilities',
      },
    ],
  },
  {
    title: 'What We Do',
    to: '/whatwedo',
    excludeInNav: false,
    children: [
      {
        title: 'Sheet Metal Fabrication',
        to: '/whatwedo/fabrication',
      },
      {
        title: 'CNC Machining',
        to: '/whatwedo/machining',
      },
      {
        title: 'Assembly',
        to: '/whatwedo/assembly',
      },
      {
        title: 'Hydroforming',
        to: '/whatwedo/hydroforming',
      },
    ],
  },
  {
    title: 'Suppliers',
    to: '/suppliers',
    excludeInNav: false,
  },
  {
    title: 'Get In Touch',
    to: '/contact',
    excludeInNav: true,
  },
];
