export default [
  '/images/assembly_photos/1.webP',
  '/images/assembly_photos/2.webP',
  '/images/assembly_photos/3.webP',
  '/images/assembly_photos/4.webP',
  '/images/assembly_photos/5.webP',
  '/images/assembly_photos/6.webP',
  '/images/assembly_photos/7.webP',
  '/images/assembly_photos/8.webP',
  '/images/assembly_photos/9.webP',
  '/images/assembly_photos/10.webP',
  '/images/assembly_photos/11.webP',
  '/images/assembly_photos/12.webP',
  '/images/assembly_photos/13.webP',
  '/images/assembly_photos/14.webP',
  '/images/assembly_photos/15.webP',
  '/images/assembly_photos/16.webP',
];
